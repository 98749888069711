import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    alertSnackBar: {
      top: '10px',
      bottom: 'auto',
      '& div': {
        borderRadius: '4px',
      },
    },
    alertError: {
      backgroundColor: '#F9F9F9',
      color: '#636363',
      '& > .MuiAlert-icon': {
        alignItems: 'center',
        color: '#F2C94C',
      },
    },
  }),
);

export default useStyles;
