import React from 'react';
import { NextPage, NextPageContext } from 'next';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { useFormatMessage } from 'evl-ui-lib';

import useStyles from './LoginPageJSS';

import { LoginPanel } from '../components/login';
import BrowserNotSupportPanel from '../components/BrowserNotSupportPanel';
import { checkIfLoggedIn } from '../auth';

interface LoginProps {
  redirectPage: string | undefined;
  panel: string | undefined;
  verify: string | undefined;
  required: string | undefined;
  alert: string | undefined;
}

interface IAlertLocalMessage {
  type: string;
  messageLocale: string;
}

const ALERT_MESSAGES: Record<string, IAlertLocalMessage> = {
  claimAsset: {
    type: 'warning',
    messageLocale: 'login.alert.claimAsset',
  },
  loginRequireOnViewAsset: {
    type: 'warning',
    messageLocale: 'login.alert.loginRequireOnViewAsset',
  },
};

const Login: NextPage<LoginProps> = ({ redirectPage, panel, verify, required, alert }: LoginProps) => {
  const translate = useFormatMessage();
  const classes = useStyles();

  const [support, setSupport] = React.useState<boolean>(true);
  const [authChecked, setAuthChecked] = React.useState<boolean>(false);
  const [alertOptions, setAlertOptions] = React.useState<{
    message?: string;
    open: boolean;
    autoHideDuration?: number;
  }>({
    message: '',
    autoHideDuration: 5000,
    open: false,
  });

  React.useEffect(() => {
    //@ts-ignore
    setSupport(window.Modernizr.arrow && window.Modernizr.flexbox);
    const fetchData = async () => {
      const authorised = await checkIfLoggedIn();
      if (authorised) {
        window.location.href = '/';
      } else {
        setAuthChecked(true);
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (alert) {
      const selectedAlert = ALERT_MESSAGES[alert];
      setAlertOptions({ message: translate(selectedAlert.messageLocale), open: true, autoHideDuration: undefined });
    }
  }, [alert]);

  function onAlertClose() {
    setAlertOptions(state => ({ ...state, message: undefined, open: false }));
  }

  return (
    <React.Fragment>
      <Snackbar
        open={alertOptions.open}
        autoHideDuration={alertOptions.autoHideDuration}
        onClose={onAlertClose}
        className={classes.alertSnackBar}
      >
        <MuiAlert
          onClose={onAlertClose}
          classes={{ standardError: classes.alertError }}
          severity="error"
          variant="standard"
        >
          {alertOptions.message}
        </MuiAlert>
      </Snackbar>
      {support || <BrowserNotSupportPanel />}
      {support && authChecked && (
        <LoginPanel
          redirectPage={redirectPage ? encodeURIComponent(decodeURIComponent(redirectPage)) : encodeURIComponent('/')}
          onLandingPage={false}
          panel={panel}
          verify={verify}
          required={required}
        />
      )}
    </React.Fragment>
  );
};

Login.getInitialProps = async (context: NextPageContext) => {
  const { redirectPage, panel, verify, required, alert } = context.query;
  if (redirectPage && typeof redirectPage !== 'string') {
    throw new Error('no_redirectPage');
  }
  if (panel && typeof panel !== 'string') {
    throw new Error('no_panel');
  }
  if (verify && typeof verify !== 'string') {
    throw new Error('no_verify');
  }
  if (required && typeof required !== 'string') {
    throw new Error('no_required');
  }
  if (alert && typeof alert !== 'string') {
    throw new Error('invalid type of alert');
  }
  return { redirectPage, panel, verify, required, alert };
};

export default Login;
